import React, { useState } from 'react'
import upload from "./images/upload.png";
import camera from "./images/camera.png";
import midphoto from "./images/midphoto.png";
import Ellipse from "./images/Ellipse.png";
import UploadGif from "./images/uploading.gif";
import Preview from './Preview';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";

const pageCSS = {
    position: "absolute",
    top: "450px",
    width: "100%",
    height: "29vh",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "row",
    display: "flex",
    overflow: "scroll",
    boxSizing: "BorderBox",
};

const EmailPage = () => {
    
    const history = useHistory();
    const [fileInput, setFileInput] = useState(null);

    const [Uploading, setUploading] = useState(false);
    const [EmailSent, setEmailSent] = useState(false);

    const [data, setData] = useState({
        Images: [],
        upload: false,
        photocapture: true,
    });

    const CaptureHandler = (e) => {
        var fileInput = document.getElementById("file");

        var filePath = fileInput.value;
        // console.log("fileInput---", filePath);

        var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

        //NOTE:- here we are  checking the file type.
        if (!allowedExtensions.exec(filePath)) {
            alert("Please choose a JPEG image.");
            fileInput.value = "";
            return;
        }

        const images = Array.from(e.target.files);
        let currentImages = data.Images;
        currentImages = [...currentImages, ...images];
        setData({
            ...data,
            Images: currentImages,
            upload: true,
            photocapture: false,
        });
    };
    const removeImageHandler = (name) => {
        console.log("IMAGE ID", name);
        const filteredImage = data.Images.filter((image) => image.name !== name);
        // console.log("filtered image array",images)
        setData({
            ...data,
            Images: filteredImage,
        });
    };

    const UploadHandler = async () => {
        setEmailSent(false);

        if (!data.upload) {
            // history.push(`/?${Searchquery}`);
            alert("Please choose a file");
            return;
        }

        var formdata = new FormData();

        if (data.Images.length === 0) {
            alert("please choose a file");
            return;
        }
        for (const file of data.Images) {
            formdata.append("files", file);
        }
        formdata.append("notes", document.getElementById("notes").value)
        setUploading(true);

        var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };

        await fetch(
            `${window.env.API_URL}/oi/api/referral/upload/email/86a31c859ca6866495048305e793934fc0c533d9`,
            requestOptions
        )
            .then((response) => {
                // console.log("RESPONSE", typeof response.status);
                setUploading(false);

                if (response.status === 400) {
                    history.push("/email/86a31c859ca6866495048305e793934fc0c533d9");
                    return;
                }
                return response.text();
            })
            .then((result) => {
                setUploading(false);
                setData({
                    ...data,
                    Images: [],
                    upload: false,
                    photocapture: true,
                });
                history.push("/email/86a31c859ca6866495048305e793934fc0c533d9");
                setEmailSent(true);

            })
            .catch((error) => {
                // console.log("this is error", error);
                alert("An error occured while sending the email. Please check your connectivity.")
                setUploading(false);
                // history.push(`/email_done/86a31c859ca6866495048305e793934fc0c533d9`);
            });

        
    };
    const FlexGrid = styled.div`
    display: flex;

    margin-top:10px;
    margin-left:5px;
    `
    const GridChild = styled.div`flex: 1;`

    const Button = styled.button`border-radius:80px;
    outline: none;
    border:2px solid #e3d0ff5c;
    width:130px`

    const UploadButton = styled.button`border-radius:80px;
    outline: none;
    border:2px solid #3981e9cc;
    width:130px;
    background:#3981e9cc`
    return (
        <div style={{ margin: "5px" }}>
            <div style={{ background: "red" }}>
                {/* header */}
                {/* start header */}
                <div
                    style={{ paddingLeft: "10px", paddingBottom: "5px", marginTop: "10px", height: "56px", width: "100%", height: "unset", backgroundColor: "#f6f6f6", color: "#3981e9cc", fontSize: "18px" }}
                >
                    Carewell Diagnostix: Referral Upload Utility
                </div>
            </div>
            <div style={{flexDirection:"row"}}>
                <div style={{ marginTop: "5px", fontSize: "15px", lineHeight: "20px", color: "#000000c2" }}>Click on the Capture button to attach referral</div>
                <div style={{ marginTop: "10px", alignItems:"flex-end", display:"flex", justifyContent:"flex-end" }}>
                    <Button
                        onClick={() => fileInput.click()}>
                        <label style={{margin:"0px"}}><img
                            src={camera}
                            style={{ outline: "none" }}
                            alt="camera"
                        />Capture</label>
                        
                    </Button></div>
            </div>
            <hr></hr>
            <input
                        type="file"
                        id="file"
                        ref={(fileInputref) => setFileInput(fileInputref)}
                        accept="image/png, image/gif, image/jpeg,image/jpg,.pdf"
                        //multiple property accepts all kind of input like image , video , doc etc...
                        // multiple
                        onChange={CaptureHandler}
                        capture="environment"
                        style={{ display: "none" }}
                    />
            
            <div>
                <div>
                    {data.upload && (
                        <div>
                            <div>
                                <Preview
                                    Images={data.Images}
                                    removeImageHandler={removeImageHandler}
                                />
                            </div>
                            <div>Notes:</div>
                            <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
                                <textarea id="notes" style={{width:"90vw"}} rows="7"></textarea>
                            </div>
                            <div style={{display:"flex", justifyContent:"center",alignItems:"center", margin:"10px"}}>
                                <UploadButton disabled={Uploading} onClick={UploadHandler}><label  style={{margin:"0px"}}>Send Email</label></UploadButton>
                                {/* Send */}
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    {Uploading && (
                        <img
                            src={UploadGif}
                            alt="Loading .."
                            style={{ height: "80px", width: "100%" }}
                        />
                    )}
                    {EmailSent && (
                       <div style={{display:"flex", justifyContent:"center", marginTop: "5px", fontSize: "15px", lineHeight: "20px", color: "#000000c2" }}><h2>Email Sent.</h2></div>
                    )}
                </div>
            </div>

        </div>
    )
}

export default EmailPage